import React from 'react';
// import { Link } from "gatsby"
// import logo from './streamlineforex.png'
class Footeren extends React.Component {

    render() {
        return (
            <>
            
             <div className="container">
               <div className="row">
                <div className="col-lg-4 col-md-12 sidenav">
            </div>
                <div className="col-lg-8 p1050">
                    <div className="fht">
                    <p><b>การเปิดเผยความเสี่ยง:</b>การซื้อขายตราสารทางการเงินและ/หรือเงินดิจิตอลจะมีความเสี่ยงสูงที่รวมถึงความเสี่ยงต่อการสูญเสียจำนวนเงินลงทุนของคุณบางส่วนหรือทั้งหมดและอาจไม่เหมาะสมกับนักลงทุนทั้งหมด ราคาของเงินดิจิตอลแปรปรวนอย่างมากและอาจได้รับผลกระทบจากปัจจัยภายนอกต่าง ๆ เช่น เหตุการณ์ทางการเงิน กฎหมายกำกับดูแล หรือ เหตุการณ์ทางการเมือง การซื้อขายด้วยมาร์จินทำให้ความเสี่ยงทางการเงินเพิ่มขึ้น. ก่อนการตัดสินใจซื้อขายตราสารทางการเงินหรือเงินดิจิตอล คุณควรตระหนักดีถึงความเสี่ยงและต้นทุนที่เกี่ยวข้องกับการซื้อขายในตลาดการเงิน ควรพิจารณาศึกษาอย่างรอบคอบในด้านวัตถุประสงค์การลงทุน ระดับประสบการณ์ และ การยอมรับความเสี่ยงและแสวงหาคำแนะนำทางวิชาชีพหากจำเป็น </p>
                    </div>
                </div>
                </div>
                </div>
           
            </>
        )
    }
}

export default Footeren;